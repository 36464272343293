import React from "react";
import styles from "./Landing.module.css";
import { ReactComponent as Logo } from "@assets/logo.svg";
import { ReactComponent as Vector } from "@assets/Vector.svg";
import { ReactComponent as Banner2 } from "@assets/screen2.svg";
import { ReactComponent as Banner1 } from "@assets/screen1.svg";
import { ReactComponent as Download } from "@assets/download.svg";
import Button from "@components/Button/Button";

const LandingBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.banner}>
        <div className={styles.textSection}>
          <h1 className={styles.title}>
            Feeling Spontaneous? <br />
            <span className={styles.highlight}>Random Connect</span>
            &nbsp;Has
            <br />
            Your Back.
          </h1>
          <p className={styles.description}>
            Break the mold, make a new friend! Random calls, video chats, or
            messages - it's all about the connection.
          </p>
          <div className={styles.buttonContainer}>
            <Button text="Get App" icon={<Download />} />
          </div>
        </div>

        <div className={styles.imageContainer}>
          <Vector className={styles.vector} />
          <Banner1 className={styles.banner1} />
          <Banner2 className={styles.banner2} />
        </div>
      </div>
    </div>
  );
};

export default LandingBanner;
