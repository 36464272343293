import React from "react";

// Define the ButtonProps interface to include optional 'icon' and 'width' props
interface ButtonProps {
  text: string;
  icon?: React.ReactNode; // This prop can accept any React node, such as an icon component or JSX element
  width?: string;
}

// Update the Button component to use the new props and styles
const Button = ({ text, icon, width }: ButtonProps) => {
  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width,
        color: "white",
        backgroundColor: "var(--primary-main-color)", // Use the primary color for text/icon for contrast
        padding: "8px 16px", // Add some padding for better spacing
        border: "2px solid var(--primary-main-color)", // Optional border to add visibility
        borderRadius: "40px", // Set border radius to 40px for a rounded appearance
        cursor: "pointer",
        fontWeight: 600,
        fontSize: "16px",
      }}
    >
      {icon && <span style={{ marginRight: icon ? "8px" : "0" }}>{icon}</span>}
      <span>{text}</span>
    </button>
  );
};

export default Button;
