import React from "react";
import LandingBanner from "./LandingBanner/LandingBanner";
import Footer from "./Footer/Footer";

const LandingPage = () => {
  return (
    <div style={{ width: "100%" }}>
      <LandingBanner />
      <Footer />
    </div>
  );
};

export default LandingPage;
