import { ReactComponent as Logo } from "@assets/logo.svg";
import styles from "./Footer.module.css";

import { ReactComponent as ApplePlayStoreBtn } from "@assets/ApplePlayStoreButton.svg";
import { ReactComponent as GooglePlayStoreBtn } from "@assets/GooglePlayButton.svg";
import { ReactComponent as CopyRight } from "@assets/Copyright.svg";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.footerColumn}>
          <div className={styles.logoContainer}>
            <Logo />
            <h3 className={styles.footerLogoText}>Random Connect</h3>
          </div>
        </div>
        <div className={styles.footerColumn}>
          <div className={styles.footerLinksContainer}>
            <p className={styles.footerLinks}>Privacy Policies</p>
            <p className={styles.footerLinks}>Terms & Conditions</p>
            <p className={styles.footerLinks}>Blogs</p>
          </div>
        </div>
        <div className={styles.footerColumn}>
          <div className={styles.downloadSvg}>
            <GooglePlayStoreBtn />
          </div>
          <div className={styles.downloadSvg}>
            <ApplePlayStoreBtn />
          </div>
        </div>
      </div>
      <div className={styles.footerCopyRight}>
        <p className={styles.copyrightText}>
          <CopyRight />
          Copyright 2024
        </p>
      </div>
    </footer>
  );
};

export default Footer;
